




import { Component, Vue } from "vue-property-decorator";
import { dispatchGetSample } from "@/store/main/actions";

@Component
export default class SampleStart extends Vue {
  public async mounted() {
    dispatchGetSample(this.$store, this.$route.params.sampleId);
  }
}
